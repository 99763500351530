import {
    createRouter,
    createWebHistory
} from "vue-router";
const routes = [
    //主页面路由,需加入id字段,与后端返的json数据对应 --------------------------------------------
    {
        // 首页-打印概览
        id: 2,
        path: '/printoverview',
        name: 'printoverview',
        component: () =>
            import ('@/views/index/printoverview/printoverview.vue')
    },
    {
        // 首页-设备会员
        id: 4,
        path: '/memberdvcbuy',
        name: 'memberdvcbuy',
        component: () =>
            import ('@/views/index/memberdvcbuy/memberdvcbuy.vue')
    },
    {
        // 首页-优惠券
        id: 8,
        path: '/coupon',
        name: 'coupon',
        component: () =>
            import ('@/views/index/coupon/coupon.vue')
    },
    {
        // 首页-推荐码
        id: 527,
        path: '/referralcode',
        name: 'referralcode',
        component: () =>
            import ('@/views/index/referralcode/referralcode.vue')
    },
    {
        // 首页-账户设置
        id: 531,
        path: '/accountset',
        name: 'accountset',
        component: () =>
            import ('@/views/index/accountset/accountset.vue')
    },
    {
        // 首页-导出列表
        id: 532,
        path: '/exportlist',
        name: 'exportlist',
        component: () =>
            import ('@/views/index/exportlist/exportlist.vue')
    },
    {
        // 设备-设备列表
        id: 12,
        path: '/dvclist',
        name: 'dvclist',
        component: () =>
            import ('@/views/device/dvclist/dvclist.vue')
    },
    {
        // 设备-设备打印佣金
        id: 533,
        path: '/dvcprintcommission',
        name: 'dvcprintcommission',
        component: () =>
            import ('@/views/device/dvcprintcommission/dvcprintcommission.vue')
    },
    {
        // 设备-设备地图
        id: 14,
        path: '/sharedvcmap',
        name: 'sharedvcmap',
        component: () =>
            import ('@/views/device/sharedvcmap/sharedvcmap.vue')
    },
    {
        // 订单-订单列表
        id: 24,
        path: '/orderlist',
        name: 'orderlist',
        component: () =>
            import ('@/views/order/orderlist/orderlist.vue')
    },
    {
        // 订单-退款申请
        id: 30,
        path: '/refundapplication',
        name: 'refundapplication',
        component: () =>
            import ('@/views/order/refundapplication/refundapplication.vue')
    },
    {
        // 代理商-代理商列表
        id: 36,
        path: '/agentlist',
        name: 'agentlist',
        component: () =>
            import ('@/views/agent/agentlist/agentlist.vue')
    },
    {
        // 财务-账户总览
        id: 47,
        path: '/account',
        name: 'account',
        component: () =>
            import ('@/views/finance/account/account.vue')
    },
    {
        // 财务-对账单明细
        id: 50,
        path: '/statementdetails',
        name: 'statementdetails',
        component: () =>
            import ('@/views/finance/statementdetails/statementdetails.vue')
    },
    {
        // 财务-提现记录
        id: 53,
        path: '/withdrawalrecord',
        name: 'withdrawalrecord',
        component: () =>
            import ('@/views/finance/withdrawal/withdrawalrecord.vue')
    },
    {
        // 统计-代理商统计排行
        id: 58,
        path: '/agentcensus',
        name: 'agentcensus',
        component: () =>
            import ('@/views/census/agentcensus/agentcensus.vue')
    },
    {
        // 统计-设备使用情况
        id: 534,
        path: '/dvcuseinfo',
        name: 'dvcuseinfo',
        component: () =>
            import ('@/views/census/dvcuseinfo/dvcuseinfo.vue')
    },
    {
        // 统计-设备订单排行
        id: 60,
        path: '/dvcorderranking',
        name: 'dvcorderranking',
        component: () =>
            import ('@/views/census/dvcorderranking/dvcorderranking.vue')
    },


    //以下是配置页面&子页面等页面路由 --------------------------------------------
    {
        //登录
        path: '/',
        name: 'login',
        component: () =>
            import ('@/views/login/login.vue')
    },
    {
        //登录-忘记密码
        path: '/loginforget',
        name: 'loginforget',
        component: () =>
            import ('@/views/login/loginforget.vue')
    },
    {
        //404
        path: '/404',
        name: '404',
        component: () =>
            import ('@/components/404.vue')
    },
    {
        //test03
        path: '/test03',
        name: 'test03',
        component: () =>
            import ('@/views/acase/test03.vue')
    },
    {
        //test01
        path: '/test',
        name: 'test',
        component: () =>
            import ('@/views/acase/test.vue')
    },
    {
        //  设备-设备列表-编辑设备
        path: '/dvcedit',
        name: 'dvcedit',
        component: () =>
            import ('@/views/device/dvclist/dvcedit.vue')
    },
    {
        //  设备-设备列表-编辑设备-机器控制
        path: '/dvcfrp',
        name: 'dvcfrp',
        component: () =>
            import ('@/views/device/dvclist/dvcfrp.vue')
    },
    {
        //设备-设备列表-在线情况
        path: '/dvcisonline',
        name: 'dvcisonline',
        component: () =>
            import ('@/views/device/dvclist/dvcisonline.vue')
    },
    {
        //财务-财务总览-手动提现
        path: '/handwithdrawal',
        name: 'handwithdrawal',
        component: () =>
            import ('@/views/finance/account/handwithdrawal.vue')
    },
    {
        //财务-财务总览-修改账户信息
        path: '/chfn',
        name: 'chfn',
        component: () =>
            import ('@/views/finance/account/chfn.vue')
    },
    {
        //财务-财务总览-交易记录
        path: '/tradedetailed',
        name: 'tradedetailed',
        component: () =>
            import ('@/views/finance/account/tradedetailed.vue')
    },
    {
        //代理商-代理商列表-代理商详情
        path: '/agentlistinfo',
        name: 'agentlistinfo',
        component: () =>
            import ('@/views/agent/agentlist/agentlistinfo.vue')
    },
    {
        //代理商-代理商列表-添加代理商
        path: '/agentlistadd',
        name: 'agentlistadd',
        component: () =>
            import ('@/views/agent/agentlist/agentlistadd.vue')
    },
    {
        //代理商-代理商列表-修改代理商
        path: '/agentlistedit',
        name: 'agentlistedit',
        component: () =>
            import ('@/views/agent/agentlist/agentlistedit.vue')
    },
    {
        //订单-订单列表-订单详情
        path: '/orderlistinfo',
        name: 'orderlistinfo',
        component: () =>
            import ('@/views/order/orderlist/orderlistinfo.vue')
    },
    {
        //订单-退款申请-查看(退款)详情
        path: '/refundapplicationinfo',
        name: 'refundapplicationinfo',
        component: () =>
            import ('@/views/order/refundapplication/refundapplicationinfo.vue')
    },
    {
        //订单-退款申请-查看(退款)详情-查看微信投诉详情
        path: '/refundwechatinfo',
        name: 'refundwechatinfo',
        component: () =>
            import ('@/views/order/refundapplication/refundwechatinfo.vue')
    },
    {
        //首页-优惠券-添加
        path: '/couponadd',
        name: 'couponadd',
        component: () =>
            import ('@/views/index/coupon/couponadd.vue')
    },
    {
        //首页-优惠券-查看
        path: '/couponinfo',
        name: 'couponinfo',
        component: () =>
            import ('@/views/index/coupon/couponinfo.vue')
    },
    {
        //首页-优惠券-数据
        path: '/coupondata',
        name: 'coupondata',
        component: () =>
            import ('@/views/index/coupon/coupondata.vue')
    },
    {
        //首页-优惠券-编辑
        path: '/couponedit',
        name: 'couponedit',
        component: () =>
            import ('@/views/index/coupon/couponedit.vue')
    },
    {
        //统计-代理商统计-下级代理商
        path: '/junioragent',
        name: 'junioragent',
        component: () =>
            import ('@/views/census/agentcensus/agentjunior.vue')
    },
    {
        //统计-代理商统计-设备数量
        path: '/setcount',
        name: 'setcount',
        component: () =>
            import ('@/views/census/agentcensus/setcount.vue')
    },
    {
        //统计-代理商统计-设备数量
        path: '/agentordermony',
        name: 'agentordermony',
        component: () =>
            import ('@/views/census/agentcensus/agentordermony.vue')
    },
    {
        //统计-代理商统计-设备数量
        path: '/agentordercount',
        name: 'agentordercount',
        component: () =>
            import ('@/views/census/agentcensus/agentordercount.vue')
    },
    {
        //统计-代理商统计-设备数量
        path: '/dvcoverprint',
        name: 'dvcoverprint',
        component: () =>
            import ('@/views/census/dvcorderranking/dvcoverprint.vue')
    },
    {
        //统计-用户加企微
        id: 535,
        path: '/dvcfanscount',
        name: 'dvcfanscount',
        component: () =>
            import ('@/views/census/wecom/dvcfanscount.vue')
    },
    {
        //统计-打印新粉统计
        id: 536,
        path: '/newFansCount',
        name: 'newFansCount',
        component: () =>
            import ('@/views/census/newFansCount/newFansCount.vue')
    },
    {
        //设备-开锁记录
        id: 537,
        path: '/deviceOpenLockLog',
        name: 'deviceOpenLockLog',
        component: () =>
            import ('@/views/device/openLock/deviceOpenLockLog.vue')
    },

    // -----------------------兼容家庭版---------------------------
    {
        // 打印-机器控制-家庭成员-关联用户
        path: '/dvclfamilyedit',
        name: 'dvclfamilyedit',
        component: () =>
            import ('@/views/device/dvclist/dvclfamilyedit.vue')
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;