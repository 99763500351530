<template>
  <div id="app">
    <router-view v-if="isRouterAlive" :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'App',
  provide(){
    return {
      reload: this.reload
    }
  },
  data(){
    return {
      isRouterAlive: true
    }
  },
  methods: {
    // 页面刷新
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  }
})
</script>

<style>
#app {
  margin: 0;
  padding: 0;
  color: #333333;
  /*background-color: #ffffff;*/
  font-family: "PingFang SC";
  letter-spacing: 0.5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconfont {
  color: #002EB7;
}

.el-divider {
  background-color: #eeeeee !important;
}

/* 修改el-loading-mask的z-index */
.el-loading-mask {
    position: absolute;
    z-index: 998;
    background-color: rgba(255, 255, 255, 0.9);
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity var(--el-transition-duration);
}

/*修改button-primary的主颜色*/
.el-button--primary {
  --el-button-font-color: #ffffff; 
  --el-button-background-color: #1ABC9C;
  --el-button-border-color: #1ABC9C;
  --el-button-hover-color: rgba(26, 188, 156,0.7);
  --el-button-active-font-color: #e6e6e6;
  --el-button-active-background-color: #0ce0b6;
  --el-button-active-border-color: #0ce0b6;
}

/*修改root-配置颜色*/
:root {
  --el-color-white: #ffffff;
  --el-color-black: #333333;
  --el-color-primary: #1ABC9C;
  --el-color-primary-light-1: #1ABC9C;
  --el-color-primary-light-2: #66b1ff;
  --el-color-primary-light-3: #79bbff;
  --el-color-primary-light-4: #8cc5ff;
  --el-color-primary-light-5: #a0cfff;
  --el-color-primary-light-6: #b3d8ff;
  --el-color-primary-light-7: #c6e2ff;
  --el-color-primary-light-8: #d9ecff;
  --el-color-primary-light-9: #ecf5ff;
  --el-color-success: #67c23a;
  --el-color-success-light: #e1f3d8;
  --el-color-success-lighter: #f0f9eb;
  --el-color-warning: #e6a23c;
  --el-color-warning-light: #faecd8;
  --el-color-warning-lighter: #fdf6ec;
  --el-color-danger: #f56c6c;
  --el-color-danger-light: #fde2e2;
  --el-color-danger-lighter: #fef0f0;
  --el-color-error: #f56c6c;
  --el-color-error-light: #fde2e2;
  --el-color-error-lighter: #fef0f0;
  --el-color-info: #909399;
  --el-color-info-light: #e9e9eb;
  --el-color-info-lighter: #f4f4f5;
  --el-text-color-primary: #333333;
  --el-text-color-regular: #606266;
  --el-text-color-secondary: #909399;
  --el-text-color-placeholder: #c0c4cc;
  --el-border-color-base: #dcdfe6;
  --el-border-color-light: #e4e7ed;
  --el-border-color-lighter: #ebeef5;
  --el-border-color-extra-light: #f2f6fc;
  --el-background-color-base: #f5f7fa;
  --el-border-width-base: 1px;
  --el-border-style-base: solid;
  --el-border-color-hover: var(--el-text-color-placeholder);
  --el-border-base: var(--el-border-width-base) var(--el-border-style-base) var(--el-border-color-base);
  --el-border-radius-base: 4px;
  --el-border-radius-small: 2px;
  --el-border-radius-round: 20px;
  --el-border-radius-circle: 100%;
  --el-box-shadow-base: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  --el-box-shadow-light: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  --el-svg-monochrome-grey: #dcdde0;
  --el-fill-base: var(--el-color-white);
  --el-font-size-extra-large: 20px;
  --el-font-size-large: 18px;
  --el-font-size-medium: 16px;
  --el-font-size-base: 14px;
  --el-font-size-small: 13px;
  --el-font-size-extra-small: 12px;
  --el-font-weight-primary: 500;
  --el-font-line-height-primary: 24px;
  --el-font-color-disabled-base: #bbb;
  --el-index-normal: 1;
  --el-index-top: 1000;
  --el-index-popper: 2000;
  --el-disabled-fill-base: var(--el-background-color-base);
  --el-disabled-color-base: var(--el-text-color-placeholder);
  --el-disabled-border-base: var(--el-border-color-light);
  --el-transition-duration: 0.3s;
  --el-transition-duration-fast: 0.2s;
  --el-transition-function-ease-in-out-bezier: cubic-bezier(0.645, 0.045, 0.355, 1);
  --el-transition-function-fast-bezier: cubic-bezier(0.23, 1, 0.32, 1);
  --el-transition-all: all var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-fade: opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-md-fade: transform var(--el-transition-duration) var(--el-transition-function-fast-bezier), opacity var(--el-transition-duration) var(--el-transition-function-fast-bezier);
  --el-transition-fade-linear: opacity var(--el-transition-duration-fast) linear;
  --el-transition-border: border-color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
  --el-transition-color: color var(--el-transition-duration-fast) var(--el-transition-function-ease-in-out-bezier);
}

/*高级筛选类弹窗的样式*/
.el-dialog__header {
  padding: var(--el-dialog-padding-primary);
  padding-bottom: 10px;
}

.el-dialog__body {
  padding-top: 10px;
  padding-right: var(--el-dialog-padding-primary);
  padding-left: calc(var(--el-dialog-padding-primary) + 0px);
  padding-bottom: var(--el-dialog-padding-primary);
  color: var(--el-text-color-regular);
  font-size: var(--el-dialog-content-font-size);
  word-break: break-all;
  border-top: 1px solid #dcdfe6;
}

.el-button--primary.is-plain {
  color: var(--el-button-background-color);
  background-color: #ecf5ff;
  border-color: var(--el-color-primary);
}

/*全局控制-table表单样式*/
.el-table {
  --el-table-border-color: var(--el-border-color-lighter);
  --el-table-border: 1px solid var(--el-table-border-color);
  --el-table-font-color: var(--el-text-color-regular);
  --el-table-header-font-color: var(--el-text-color-regular);
  --el-table-row-hover-background-color: var(--el-background-color-base);
  --el-table-current-row-background-color: var(--el-color-primary-light-9);
  --el-table-header-background-color: var(--el-color-white);
  --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
  --el-table-background-color: var(--el-color-white);
  --el-table-tr-background-color: var(--el-color-white);
  --el-table-expanded-cell-background-color: var(--el-color-white);
  border-radius: 4px;
}

.el-table .thead {
  color: var(--el-text-color-regular);
  font-weight: 0 !important;
}

.el-table .el-table__cell {
  padding: 5px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: left;
}

/*table表中识别 /n 数据换行*/
.el-table .cell {
  white-space: pre-line !important;
}

/*全局控制-滑动条*/
::-webkit-scrollbar-button:horizontal:start {
  background-image: url("assets/imgs/hua/hualeft.png");
  background-position: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-button:horizontal:end {
  background-image: url("assets/imgs/hua/huaright.png");
  background-position: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-button:vertical:start {
  background-image: url("assets/imgs/hua/huatop.png");
  background-position: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar-button:vertical:end {
  background-image: url("assets/imgs/hua/huadown.png");
  background-position: 50% 50%;
  background-size: 50% 50%;
  background-repeat: no-repeat;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
  background-color: #f8f8f8;
  border-radius: 5px;

}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border-radius: 999px;
  border: 6px solid transparent;
}

::-webkit-scrollbar-track {
  border-radius: 999px;
  border: 5px solid transparent;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.11) inset;
}

::-webkit-scrollbar-thumb {
  min-height: 20px;
  background-clip: content-box;
  box-shadow: 0 0 0 5px #BFC7E0 inset;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
